import React from 'react'
import { Carousel } from 'react-bootstrap'
import { Tooltip } from 'antd'
import './App.css'
import 'antd/dist/antd.css'
import slide01 from './assets/home/01.jpg'
import slide02 from './assets/home/02.jpg'
import slide03 from './assets/home/03.jpg'
import slide04 from './assets/home/04.jpg'
import ubicacion01 from './assets/distancias/ubicacion-a.jpg'
import ubicacion02 from './assets/distancias/ubicacion-b.jpg'
import link01 from './assets/link/00_cabanas.jpg'
import link02 from './assets/link/00_turisticos.jpg'
import video from './assets/video/chanchan2023.mp4'
import distancias01 from './assets/distancias/distancias_01.png'
import distancias02 from './assets/distancias/distancias_02.png'
import distancias03 from './assets/distancias/distancias_03.png'

class SectionHome extends React.Component {
  render () {
    const maps = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50354.4135498394!2d-73.29468522218798!3d-37.92690627973194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966a6fe99b74bf51%3A0x24bf8e6e006f2d95!2sCaba%C3%B1as%20Condominio%20Chan%20Chan!5e0!3m2!1ses-419!2scl!4v1591068090030!5m2!1ses-419!2scl" width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'

    function Iframe (props) {
      return (<div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />)
    }
    return (
      <React.Fragment>
        {/* slider */}
        <Carousel>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={slide01}
              alt='slide01'
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={slide02}
              alt='slide02'
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={slide03}
              alt='slide03'
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={slide04}
              alt='slide04'
            />
          </Carousel.Item>
        </Carousel>
        {/* video - precios */}
        <div className='titulo-secciones'>Video</div>
        <div className='video-section'>
          <video
            className='video'
            controls
            autoPlay
            src={video}
          />
        </div>
        <div className='lago-div'>
          <h5 className='mixestilo01'>Entre la Cordillera de Nahuelbuta y el Océano Pacífico, al sur de la Región del Biobío, se encuentra el Lago Lanalhue y junto a su ribera las Cabañas Chan Chan, las que son acompañadas de un entorno natural de gran belleza por la gran diversidad de especies que poseen sus jardines, el cual será el escenario perfecto para que pases un tiempo de descanso, tranquilidad y desconexión</h5>
        </div>
        <div className='links'>
          <a onClick={this.props.cabanas} href='#cabanas' alt='Cabañas' className='contain-link'><img src={link01} width='100%' height='100%' alt='' /></a>
          <a onClick={this.props.atractivos} href='#atractivos' alt='Atractivos Turisticos' className='contain-link'><img src={link02} width='100%' height='100%' alt='' /></a>
        </div>
        <div className='element-ubi-div'>
          <Tooltip title='1.- Recepción/Artesanias' className='ubicacion-elements' />
          <Tooltip title='2.- Punto Limpio/Basureros' className='ubicacion-elements-2' />
          <Tooltip title='3.- Cabaña El Avellano' className='ubicacion-elements-3' />
          <Tooltip title='4.- Cabaña Los Encinos' className='ubicacion-elements-4' />
          <Tooltip title='5.- Cabaña Los Cipreses' className='ubicacion-elements-5' />
          <Tooltip title='6.- Jardines' className='ubicacion-elements-6' />
          <Tooltip title='7.- Cabaña El Castaño' className='ubicacion-elements-7' />
          <Tooltip title='8.- Cancha' className='ubicacion-elements-8' />
          <Tooltip title='9.- Juegos Infantiles' className='ubicacion-elements-9' />
          <Tooltip title='10.- Tinaja 2' className='ubicacion-elements-10' />
          <Tooltip title='11.- Kayaks' className='ubicacion-elements-11' />
          <Tooltip title='12.- Muelle' className='ubicacion-elements-12' />
          <Tooltip title='13.- Playa' className='ubicacion-elements-13' />
          <Tooltip title='14.- Tinaja 1' className='ubicacion-elements-14' />
          <Tooltip title='15.- Fogón' className='ubicacion-elements-15' />
        </div>
        <div className='titulo-secciones'>Ubicaciones</div>
        <div className='ubicacion-div' />
        <div className='carousel-mobile'>
        {/* ubicación mobile */}
        <Carousel>
          <Carousel.Item>
            <img
              className='w-100'
              src={ubicacion01}
              alt='ubicacion01'
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='w-100'
              src={ubicacion02}
              alt='ubicacion02'
            />
          </Carousel.Item>
        </Carousel>
        </div>
        <div className='titulo-secciones'>Mapa</div>
        <div id='maps' className='maps-div'>
          <Iframe iframe={maps} />
        </div>
        <div className='titulo-secciones'>Distancia</div>
        <div className='distancias-div'>
          <div><img className='dis01' src={distancias01} alt='distancias01' /></div>
          <div className='distancias02'><img className='dis02' src={distancias02} alt='distancias01' /></div>
          <div><img className='dis03' src={distancias03} alt='distancias01' /></div>
        </div>
      </React.Fragment>
    )
  }
}

export default SectionHome
