import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Modal, Drawer, Button } from 'antd'
import './../App.css'
import 'antd/dist/antd.css'

import link01 from './../assets/lightbox/avellano/avellano001.jpg'
import link02 from './../assets/lightbox/avellano/avellano002.jpg'
import link03 from './../assets/lightbox/avellano/avellano003.jpg'
import link04 from './../assets/lightbox/avellano/avellano004.jpg'
import link05 from './../assets/lightbox/avellano/avellano005.jpg'
import link06 from './../assets/lightbox/avellano/avellano006.jpg'
import link07 from './../assets/lightbox/avellano/avellano007.jpg'
import link08 from './../assets/lightbox/avellano/avellano008.jpg'
import link09 from './../assets/lightbox/avellano/avellano009.jpg'
import link10 from './../assets/lightbox/avellano/avellano010.jpg'
import link11 from './../assets/lightbox/avellano/avellano011.jpg'

const PopUpAvellano = (props) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
    return (
      <>
        <Modal
          centered
          visible={props.show}
          onOk={() => props.closePopup(false)}
          onCancel={() => props.closePopup(false)}
          footer={null}
          width={'100%'}
        >
          <Button type="primary" onClick={showDrawer}>Detalles: Cabaña El Avellano</Button>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link01}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link02}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link03}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link04}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link05}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link06}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link07}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link08}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link09}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link10}
                  alt='avellano'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link11}
                  alt='avellano'
                />
              </Carousel.Item>
            </Carousel>
            <Drawer
                title="Cabañas"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
              >
                <h3>Cabaña “El Avellano”</h3>
              <p>Cabaña de dos pisos para un máximo de 9 personas (a 100 metros del lago).</p>
              <ul> Equipada con:
                <li>1 Dormitorio con cama de 2 plazas en primer piso</li>
                <li>1 Baño (sin tina) en primer piso</li>
                <li>1 Dormitorio con cama de 2 plazas en segundo piso</li>
                <li>1 Baño (sin tina) en segundo piso</li>
                <li>1 Dormitorio con una cama de 1 plaza y media y una litera de 1 plaza para 2 personas</li>
                <li>1 Dormitorio pequeño con una cama de 1 plaza</li>
                <li>Living - Comedor</li>
                <li>Cocina completamente equipada</li>
                <li>TV Satelital</li>
                <li>Amplia terraza</li>
                <li>Asadera</li>
                <li>Estacionamiento para varios vehículos (2-3)</li>
                <li>Calefacción a leña</li>
              </ul>
            </Drawer>
        </Modal>      
      </>
    )
  }

export default PopUpAvellano
