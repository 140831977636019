import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Modal, Drawer, Button } from 'antd'
import './../App.css'
import 'antd/dist/antd.css'

import link01 from './../assets/lightbox/castano/castano001.jpg'
import link02 from './../assets/lightbox/castano/castano002.jpg'
import link03 from './../assets/lightbox/castano/castano003.jpg'
import link04 from './../assets/lightbox/castano/castano004.jpg'
import link05 from './../assets/lightbox/castano/castano005.jpg'
import link06 from './../assets/lightbox/castano/castano006.jpg'
import link07 from './../assets/lightbox/castano/castano007.jpg'
import link08 from './../assets/lightbox/castano/castano008.jpg'
import link09 from './../assets/lightbox/castano/castano009.jpg'
import link10 from './../assets/lightbox/castano/castano010.jpg'
import link11 from './../assets/lightbox/castano/castano011.jpg'

const PopUpCastano = (props) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
    return (
      <>
        <Modal
          centered
          visible={props.show}
          onOk={() => props.closePopup(false)}
          onCancel={() => props.closePopup(false)}
          footer={null}
          width={'100%'}
        >
          <Button type="primary" onClick={showDrawer}>Detalles: Cabaña El Castaño</Button>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link01}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link02}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link03}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link04}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link05}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link06}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link07}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link08}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link09}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link10}
                  alt='castaño'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link11}
                  alt='castaño'
                />
              </Carousel.Item>
            </Carousel>
            <Drawer
                title="Cabañas"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
              >
              <h3>Cabaña “El Castaño”</h3>
              <p>Cabaña de un piso para un máximo de 5 personas (a 50 metros del lago).</p>
              <ul> Equipada con:
                <li>Un dormitorio con cama 2 plazas</li>
                <li>Un dormitorio con una cama 1 plaza y media y una litera de 1 plaza para dos personas</li>
                <li>Un baño (con tina)</li>
                <li>Living - Comedor</li>
                <li>Cocina completamente equipada</li>
                <li>TV Satelital</li>
                <li>Amplia terraza</li>
                <li>Asadera</li>
                <li>Calefacción a leña</li>
                <li>Estacionamiento para 2 vehículos</li>
              </ul>
            </Drawer>
          </Modal>
      </>
    )
}

export default PopUpCastano
