import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Modal, Drawer, Button } from 'antd'
import './../App.css'
import 'antd/dist/antd.css'

import link01 from './../assets/lightbox/exterior/exterior001.jpg'
import link02 from './../assets/lightbox/exterior/exterior002.jpg'
import link03 from './../assets/lightbox/exterior/exterior003.jpg'
import link04 from './../assets/lightbox/exterior/exterior004.jpg'
import link05 from './../assets/lightbox/exterior/exterior005.jpg'
import link06 from './../assets/lightbox/exterior/exterior006.jpg'
import link07 from './../assets/lightbox/exterior/exterior007.jpg'
import link08 from './../assets/lightbox/exterior/exterior008.jpg'
import link09 from './../assets/lightbox/exterior/exterior009.jpg'
import link10 from './../assets/lightbox/exterior/exterior010.jpg'
import link11 from './../assets/lightbox/exterior/exterior011.jpg'
import link12 from './../assets/lightbox/exterior/exterior012.jpg'
import link13 from './../assets/lightbox/exterior/exterior013.jpg'

const PopUpExterior = (props) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
    return (
      <>
        <Modal
          centered
          visible={props.show}
          onOk={() => props.closePopup(false)}
          onCancel={() => props.closePopup(false)}
          footer={null}
          width={'100%'}
        >
          <Button type="primary" onClick={showDrawer}>Detalles: Exterior Cabañas</Button>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link01}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link02}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link03}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link04}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link05}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link06}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link07}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link08}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link09}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link10}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link11}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link12}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link13}
                  alt='exterior cabañas'
                />
              </Carousel.Item>
            </Carousel>
            <Drawer
                title="Exterior Cabañas"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
              >
              <h3>Exterior Cabañas</h3>
              <p>Nuestras cabañas se encuentran en aproximadamente una hectárea de terreno rodeadas de mucha vegetación, la que incluye árboles nativos, árboles frutales, diversidad de flores y arbustos. Posee una gran playa con acceso al lago, área de juegos, cancha de pasto, sector de fogata, sector de tinaja de agua caliente (hot tubs), etc.</p>
            </Drawer>
        </Modal>
      </>
    )
}

export default PopUpExterior
