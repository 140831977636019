import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Modal, Drawer, Button } from 'antd'
import './../App.css'
import 'antd/dist/antd.css'

import link01 from './../assets/lightbox/patrimoniales/01_patrimoniales.jpg'
import link02 from './../assets/lightbox/patrimoniales/02_patrimoniales.jpg'
import link03 from './../assets/lightbox/patrimoniales/03_patrimoniales.jpg'
import link04 from './../assets/lightbox/patrimoniales/04_patrimoniales.jpg'
import link05 from './../assets/lightbox/patrimoniales/05_patrimoniales.jpg'
import link06 from './../assets/lightbox/patrimoniales/06_patrimoniales.jpg'
import link07 from './../assets/lightbox/patrimoniales/07_patrimoniales.jpg'
import link08 from './../assets/lightbox/patrimoniales/08_patrimoniales.jpg'
import link09 from './../assets/lightbox/patrimoniales/09_patrimoniales.jpg'
import link10 from './../assets/lightbox/patrimoniales/10_patrimoniales.jpg'

const PopUpPatrimoniales = (props) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
    return (
      <>
        <Modal
          centered
          visible={props.show}
          onOk={() => props.closePopup(false)}
          onCancel={() => props.closePopup(false)}
          footer={null}
          width={'100%'}
        >
          <Button type="primary" onClick={showDrawer}>Detalles: Contulmo y Casas Patrimoniales</Button>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link01}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link02}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link03}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link04}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link05}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link06}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link07}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link08}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link09}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link10}
                  alt='casas patrimoniales'
                />
              </Carousel.Item>
            </Carousel>
            <Drawer
                title="Atractivos Turisticos"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
              >
              <h3>Contulmo y Casas Patrimoniales</h3>
              <p>Compuesta  por  más de una decena de antiguas casas alemanas. Comprende un recorrido por la zona urbana de Contulmo en la que podrá apreciar los diseños, la nobleza de los materiales utilizados y  la solidez de sus construcciones.</p>
              <p>Estas viviendas edificadas entre 1884 y 1934, forman parte del legado arquitectónico que dejaron las familias de colonos alemanes que llegaron a nuestro país.</p>
            </Drawer>
        </Modal>
      </>
    )
  }
  
  export default PopUpPatrimoniales
