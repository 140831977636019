import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Modal, Drawer, Button } from 'antd'
import './../App.css'
import 'antd/dist/antd.css'

import link01 from './../assets/lightbox/rayen/rayen01.jpg'
import link02 from './../assets/lightbox/rayen/rayen02.jpg'
import link03 from './../assets/lightbox/rayen/rayen03.jpg'
import link04 from './../assets/lightbox/rayen/rayen04.jpg'
import link05 from './../assets/lightbox/rayen/rayen05.jpg'
import link06 from './../assets/lightbox/rayen/rayen06.jpg'
import link07 from './../assets/lightbox/rayen/rayen07.jpg'
import link08 from './../assets/lightbox/rayen/rayen08.jpg'

const PopUpRayen = (props) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
    return (
      <>
        <Modal
          centered
          visible={props.show}
          onOk={() => props.closePopup(false)}
          onCancel={() => props.closePopup(false)}
          footer={null}
          width={'100%'}
        >
          <Button type="primary" onClick={showDrawer}>Detalles: Salto Rayen</Button>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link01}
                  alt='salto rayen'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link02}
                  alt='salto rayen'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link03}
                  alt='salto rayen'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link04}
                  alt='salto rayen'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link05}
                  alt='salto rayen'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link06}
                  alt='salto rayen'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link07}
                  alt='salto rayen'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link08}
                  alt='salto rayen'
                />
              </Carousel.Item>
            </Carousel>
            <Drawer
                title="Atractivos Turisticos"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
              >
              <h3>Salto Rayen</h3>
              <p>Ubicado a 9 km de Contulmo en la ruta que une Contulmo – Puren.</p>
              <p>Para llegar a los saltos, con caídas de 17 y 22 metros, se debe caminar a través de un sendero, cruzar puentes y pasarelas de madera por alrededor de 1,5 kilómetros.</p>
              <p>Además del atractivo de las caídas de agua es la exuberante vegetación nativa, donde destacan los helechos, musgos, arrayanes, canelos, avellanos, robles, copihues y muchas especies más.</p>
              <p>También se pueden observar aves y fauna propia de la Cordillera de Nahuelbuta.</p>
            </Drawer>
        </Modal>
      </>
    )
  }
  
  export default PopUpRayen
