import React from "react";
import { Carousel } from "react-bootstrap";
import { Collapse, Select } from "antd";
import "antd/dist/antd.css";
import "./App.css";
// import webpay from "./assets/tarifas/webpay.png";
import Opciones from "./select.json";
import slide01 from "./assets/reservas/01.jpg";
import slide02 from "./assets/reservas/02.jpg";
import slide03 from "./assets/reservas/03.jpg";
import slide04 from "./assets/reservas/04.jpg";
import { SettingOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const { Option } = Select;

function callback(key) {
  console.log(key);
}

const genExtra = () => (
  <SettingOutlined
    onClick={(event) => {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    }}
  />
);

class SectionReservas extends React.Component {
  state = {
    expandIconPosition: "right",
    sectionCastano: "0",
    sectionEncinos: "0",
    sectionCipreses: "0",
    sectionAvellano: "0"
  };

  handleChangeCastano = (value) => {
    this.setState({ sectionCastano: value });
  };

  handleChangeEncinos = (value) => {
    this.setState({ sectionEncinos: value });
  };

  handleChangeCipreses = (value) => {
    this.setState({ sectionCipreses: value });
  };

  handleChangeAvellano = (value) => {
    this.setState({ sectionAvellano: value });
  };

  onPositionChange = (expandIconPosition) => {
    this.setState({ expandIconPosition });
  };

  render() {
    const { expandIconPosition } = this.state;
    return (
      <React.Fragment>
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100" src={slide01} alt="slide01" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={slide02} alt="slide02" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={slide03} alt="slide03" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={slide04} alt="slide04" />
          </Carousel.Item>
        </Carousel>
        <div className="titulo-secciones">Tarifas</div>
        <div className="price-section">
          <h5 className="temporadas">Nuestras Temporadas</h5>
          <div className="price-block">
            <div className="price-title">Nombre</div>
            <div className="price-title">Capacidad Cabaña</div>
            <div className="price-title">Seleccionar Nº PERSONAS</div>
            <div className="price-title">Baja</div>
            <div className="price-title active">Alta</div>
          </div>
          <div className="price-block">
            <div className="price-element">El Castaño</div>
            <div className="price-element">2 a 5 personas</div>
            <div className="price-element">
              <Select
                defaultValue="Nº Personas"
                onChange={this.handleChangeCastano}
              >
                {Opciones.castano.map((item, idx) => (
                  <Option name={item.NAME} value={item.PLAN} key={idx}>
                    {item.PLAN}
                  </Option>
                ))}
              </Select>
            </div>
            {this.state.sectionCastano === "2" ? (
              <>
                <div className="price-element">$ 75.000</div>
                <div className="price-element active">$ 90.000</div>
              </>
            ) : this.state.sectionCastano === "3" ? (
              <>
                <div className="price-element">$ 90.000</div>
                <div className="price-element active">$ 100.000</div>
              </>
            ) : this.state.sectionCastano === "4" ? (
              <>
                <div className="price-element">$ 100.000</div>
                <div className="price-element active">$ 110.000</div>
              </>
            ) : this.state.sectionCastano === "5" ? (
              <>
                <div className="price-element">$ 110.000</div>
                <div className="price-element active">$ 125.000</div>
              </>
            ) : (
              <>
                <div className="price-element">$ Valores</div>
                <div className="price-element active">$ Valores</div>
              </>
            )}
          </div>
          <div className="price-block">
            <div className="price-element">Los Encinos</div>
            <div className="price-element">4 a 8 personas</div>
            <div className="price-element">
              <Select
                defaultValue="Nº Personas"
                onChange={this.handleChangeEncinos}
              >
                {Opciones.encinos.map((item, idx) => (
                  <Option name={item.NAME} value={item.PLAN} key={idx}>
                    {item.PLAN}
                  </Option>
                ))}
              </Select>
            </div>
            {this.state.sectionEncinos === "4" ? (
              <>
                <div className="price-element">$ 100.000</div>
                <div className="price-element active">$ 110.000</div>
              </>
            ) : this.state.sectionEncinos === "5" ? (
              <>
                <div className="price-element">$ 110.000</div>
                <div className="price-element active">$ 125.000</div>
              </>
            ) : this.state.sectionEncinos === "6" ? (
              <>
                <div className="price-element">$ 120.000</div>
                <div className="price-element active">$ 130.000</div>
              </>
            ) : this.state.sectionEncinos === "7" ? (
              <>
                <div className="price-element">$ 130.000</div>
                <div className="price-element active">$ 145.000</div>
              </>
            ) : this.state.sectionEncinos === "8" ? (
              <>
                <div className="price-element">$ 140.000</div>
                <div className="price-element active">$ 150.000</div>
              </>
            ) : (
              <>
                <div className="price-element">$ Valores</div>
                <div className="price-element active">$ Valores</div>
              </>
            )}
          </div>
          <div className="price-block">
            <div className="price-element">Los Cipreses</div>
            <div className="price-element">5 a 8 personas</div>
            <div className="price-element">
              <Select
                defaultValue="Nº Personas"
                onChange={this.handleChangeCipreses}
              >
                {Opciones.cipreses.map((item, idx) => (
                  <Option name={item.NAME} value={item.PLAN} key={idx}>
                    {item.PLAN}
                  </Option>
                ))}
              </Select>
            </div>
            {this.state.sectionCipreses === "5" ? (
              <>
                <div className="price-element">$ 110.000</div>
                <div className="price-element active">$ 125.000</div>
              </>
            ) : this.state.sectionCipreses === "6" ? (
              <>
                <div className="price-element">$ 120.000</div>
                <div className="price-element active">$ 130.000</div>
              </>
            ) : this.state.sectionCipreses === "7" ? (
              <>
                <div className="price-element">$ 130.000</div>
                <div className="price-element active">$ 145.000</div>
              </>
            ) : this.state.sectionCipreses === "8" ? (
              <>
                <div className="price-element">$ 140.000</div>
                <div className="price-element active">$ 150.000</div>
              </>
            ) : (
              <>
                <div className="price-element">$ Valores</div>
                <div className="price-element active">$ Valores</div>
              </>
            )}
          </div>
          <div className="price-block">
            <div className="price-element">El Avellano</div>
            <div className="price-element">6 a 9 personas</div>
            <div className="price-element">
              <Select
                defaultValue="Nº Personas"
                onChange={this.handleChangeAvellano}
              >
                {Opciones.avellano.map((item, idx) => (
                  <Option name={item.NAME} value={item.PLAN} key={idx}>
                    {item.PLAN}
                  </Option>
                ))}
              </Select>
            </div>
            {this.state.sectionAvellano === "6" ? (
              <>
                <div className="price-element">$ 120.000</div>
                <div className="price-element active">$ 130.000</div>
              </>
            ) : this.state.sectionAvellano === "7" ? (
              <>
                <div className="price-element">$ 130.000</div>
                <div className="price-element active">$ 145.000</div>
              </>
            ) : this.state.sectionAvellano === "8" ? (
              <>
                <div className="price-element">$ 140.000</div>
                <div className="price-element active">$ 150.000</div>
              </>
            ) : this.state.sectionAvellano === "9" ? (
              <>
                <div className="price-element">$ 150.000</div>
                <div className="price-element active">$ 165.000</div>
              </>
            ) : (
              <>
                <div className="price-element">$ Valores</div>
                <div className="price-element active">$ Valores</div>
              </>
            )}
          </div>
          <div className="text-conditions">
            <p>- Todos los valores son con IVA incluido (19%).</p>
            <p>
              - La capacidad de la cabaña incluye adultos y niños (mayores de 3
              años).
            </p>
            <p>
              - Opción de cuna para bebés de hasta 15 kg por un recargo de
              $10.000 diarios (sujeto a disponibilidad)
            </p>
          </div>
          <div className="text-conditions">
            <p>
              <b>TEMPORADA BAJA:</b> 13 de Marzo al 21 de Diciembre 2023
            </p>
            <p>
              <b>TEMPORADA ALTA:</b> 22 de Diciembre 2023 al 10 de Marzo 2024
              <br />
              <i>
              (Noche de Año Nuevo posee tarifa especial / Arriendo mínimo 3 noches en Enero y 4 noches en Febrero)
              </i>
            </p>
          </div>
        </div>
        <div className="titulo-secciones">Reservas</div>
        <div className="price-section">
          <div className="text-conditions">
            <b>Procedimiento Reserva: </b>
            <br />
            <p>
              Depósito, transferencia o pago con tarjetas bancarias
              (débito/crédito) del 50% de la estadía por dos o más noches y 100%
              por una noche.
            </p>
            <p>
              Para abono de reserva por depósito o transferencia solicitar
              número de cuenta a través de whatsapp (+56965005083).
            </p>
          </div>
          {/* <a href='https://www.webpay.cl/portalpagodirecto/pages/institucion.jsf?idEstablecimiento=36745135' target='_blank' rel='noopener noreferrer'>
            <img
              className='webpay-link'
              src={webpay}
              alt='webpay'
            />
          </a> */}
          <Collapse
            defaultActiveKey={["1"]}
            onChange={callback}
            expandIconPosition={expandIconPosition}
          >
            <Panel
              header="Quien realicé la reserva debe entregar la siguiente información:"
              key="1"
              extra={genExtra()}
            >
              <div>
                <ol>
                  <li>Nombre y Apellidos</li>
                  <li>Rut</li>
                  <li>Comuna o Ciudad de Residencia</li>
                  <li>País</li>
                  <li>Número acompañantes adultos y niños</li>
                </ol>
              </div>
            </Panel>
            <Panel
              header="Políticas de Cancelación de Reserva: "
              key="2"
              extra={genExtra()}
            >
              <div>
                Antes de 20 días de la llegada devolución del 100% de la
                reserva. Entre 20 y 7 tiene una penalidad del 50% de la reserva
                y menos de 7 días el 100% de la reserva.
              </div>
            </Panel>
            {/* <Panel header="Excepciones COVID-19" key="3" extra={genExtra()}>
            <div>En caso que la Comuna de Contulmo (donde se encuentran las cabañas) retrocediera a Fase 1 del Plan Paso a Paso se hará devolución del 100 % de la reserva a todos los viajeros (si el viajero lo desea opción de reprogramar)</div>
          </Panel> */}
            <Panel
              header="Procedimiento Check in (ingreso): "
              key="4"
              extra={genExtra()}
            >
              <ul>
                <li>
                  El horario de check in o ingreso es desde las 15:00 hrs en
                  Temporada Baja y desde las 16:00 hrs en Temporada.
                </li>
                <li>
                  Antes de su llegada se solicita avisar al contacto de las
                  cabañas.
                </li>
                <li>
                  A su llegada se debe pasar a la Recepción para firmar
                  formulario de registro por quien realizó la reserva y pagar el
                  saldo pendiente de la estadía (efectivo o tarjeta bancaria),
                  posterior a ello se mostrará cabaña, entregarán llaves y se
                  darán indicaciones y recomendaciones generales.
                </li>
                {/* <li>Los huéspedes (mayores de 18 años) deberán firmar una declaración jurada de salud <a href='http://condominiochanchan.com/DeclaracionJuradaSalud.pdf' target='_blank' rel='noopener noreferrer'>(Descarga aquí)</a>, la cual deberá ser entregada a su llegada. Si no la trae tendrá que realizarlo en ese momento en recepción (Protocolo Covid-19).</li> */}
              </ul>
            </Panel>
            <Panel
              header="Procedimiento Check out (salida): "
              key="5"
              extra={genExtra()}
            >
              <ul>
                <li>
                  El horario de check out o salida es hasta las 12:00 hrs en
                  Temporada Baja y 11:30 hrs en Temporada Alta . Solicitamos
                  respetar el horario.
                </li>
                <li>
                  Se realizará una revisión general de la cabaña por parte de la
                  Administración.
                </li>
                <li>
                  Se solicita dejar ordenado, losa lavada, toallas usadas en
                  baños y depositar basura y reciclaje en sector habilitado.
                </li>
                <li>
                  Artículos dañados y/o perdidos deben pagarse antes de
                  retirarse en Recepción.
                </li>
              </ul>
            </Panel>
          </Collapse>
        </div>
      </React.Fragment>
    );
  }
}

export default SectionReservas;
