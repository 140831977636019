import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Modal, Drawer, Button } from 'antd'
import './../App.css'
import 'antd/dist/antd.css'

import link01 from './../assets/lightbox/dungunwe/01_dungunwe.jpg'
import link02 from './../assets/lightbox/dungunwe/02_dungunwe.jpg'
import link03 from './../assets/lightbox/dungunwe/03_dungunwe.jpg'
import link04 from './../assets/lightbox/dungunwe/04_dungunwe.jpg'
import link05 from './../assets/lightbox/dungunwe/05_dungunwe.jpg'
import link06 from './../assets/lightbox/dungunwe/06_dungunwe.jpg'
import link07 from './../assets/lightbox/dungunwe/07_dungunwe.jpg'

const PopUpDungunwe = (props) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
    return (
      <>
        <Modal
          centered
          visible={props.show}
          onOk={() => props.closePopup(false)}
          onCancel={() => props.closePopup(false)}
          footer={null}
          width={'100%'}
        >
          <Button type="primary" onClick={showDrawer}>Detalles: Antiguos Museo Dungunwe</Button>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link01}
                  alt='museo dungunwe'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link02}
                  alt='museo dungunwe'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link03}
                  alt='museo dungunwe'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link04}
                  alt='museo dungunwe'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link05}
                  alt='museo dungunwe'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link06}
                  alt='museo dungunwe'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link07}
                  alt='museo dungunwe'
                />
              </Carousel.Item>
            </Carousel>
            <Drawer
                title="Atractivos Turisticos"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
              >
              <h3>Museo Dungunwe</h3>
              <p>Espacio creado para  atesorar el legado historico-cultural que las culturas, alemana, mapuche y chilena dejaron generación tras generación en el desarrollo de la Comuna de Contulmo.</p>
              <p>En el podrá encontrar elementos domésticos así como también documentos históricos y un ambiente lleno de recuerdos digno de visitar.</p>
            </Drawer>
        </Modal>
      </>
    )
  }
  
  export default PopUpDungunwe
