import React from 'react'
import { Navbar, Nav, Form } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from './assets/img/logo.png'
import fixedButtonMobile from './assets/img/consultas_small.png'
import fixedCallMobile from './assets/img/llamadas_small.png'
import ubicacion from './assets/img/ubicacion.png'
import facebook from './assets/img/facebook.png'
import instagram from './assets/img/instagram.png'
import mensaje from './assets/img/mensaje.png'
import confianza from './assets/confianza-turistica.png'
import turistico from './assets/servicio-turistico.png'
import bookingDesktop from './assets/logo-booking.png'
import bookingMobile from './assets/logo-booking_mobile.png'
import whatsapp from './assets/img/whatsapp.png'
import SectionHome from './SectionHome'
import SectionCabanas from './SectionCabanas'
import SectionAtractivos from './SectionAtractivos'
import SectionPromociones from './SectionPromociones'
import SectionFaq from './SectionFaq'
import SectionReservas from './SectionReservas'
import link13 from './assets/promociones/13.png'
import './App.css'

class Info extends React.Component {
  render () {
    return (
      <div className='popup'>
        <div className='popup_inner'>
          <div className='text-up-info'>
            <div className='button-close-info' onClick={this.props.closePopup}>x</div>
            <div alt='promociones'><a href='https://api.whatsapp.com/send?phone=56954005083&text=Hola,%20Me%20Gustaría%20tener%20información%20de%20Cabañas%20Chan%20Chan'><img src={link13} width='100%' height='100%' alt='Promociones'/></a></div>
          </div>
        </div>
      </div>
    )
  }
}

export default class App extends React.Component {
  constructor (props) {
    super(props)
    this.handleSectionHome = this.handleSectionHome.bind(this)
    this.handleSectionCabanas = this.handleSectionCabanas.bind(this)
    this.handleSectionAtractivos = this.handleSectionAtractivos.bind(this)
    this.handleSectionPromociones = this.handleSectionPromociones.bind(this)
    this.handleSectionFaq = this.handleSectionFaq.bind(this)
    this.handleSectionReservas = this.handleSectionReservas.bind(this)
    this.state = {
      sectionActive: 1,
      showPopup: false
    }
  }
  handleSectionHome () {
    this.setState({ sectionActive: 1 })
  }
  handleSectionCabanas () {
    this.setState({ sectionActive: 2 })
  }
  handleSectionAtractivos () {
    this.setState({ sectionActive: 3 })
  }
  handleSectionPromociones () {
    this.setState({ sectionActive: 4 })
  }
  handleSectionFaq () {
    this.setState({ sectionActive: 5 })
  }
  handleSectionReservas () {
    this.setState({ sectionActive: 6 })
  }
  togglePopup () {
    this.setState({
      showPopup: !this.state.showPopup
    })
  }

  render () {
  // Select the different section to show in the report
    const sectionActive = this.state.sectionActive
    let report
    if (sectionActive === 1) {
      report = <SectionHome cabanas={this.handleSectionCabanas} atractivos={this.handleSectionAtractivos} protocolo={this.handleSectionReservas} />
    } else if (sectionActive === 2) {
      report = <SectionCabanas />
    } else if (sectionActive === 3) {
      report = <SectionAtractivos />
    } else if (sectionActive === 4) {
      report = <SectionPromociones />
    } else if (sectionActive === 5) {
      report = <SectionFaq />
    } else if (sectionActive === 6) {
      report = <SectionReservas />
    } else {
      console.log('nO DEBERIA nunca llegar aqui!!')
    }
    // pop-up information
    const informationActive = this.state.showPopup
    let lightboxInformation
    if (informationActive === true) {
      lightboxInformation = <Info closePopup={this.togglePopup.bind(this)} />
    }
    return (
      <div className='App'>
        {lightboxInformation}
        <a className='fixed-button-desktop' href='https://api.whatsapp.com/send?phone=56954005083&text=Hola,%20Me%20Gustaría%20tener%20información%20de%20Cabañas%20Chan%20Chan'>Consultas y Reservas</a>
        <a className='fixed-button-mobile' href='https://api.whatsapp.com/send?phone=56954005083&text=Hola,%20Me%20Gustaría%20tener%20información%20de%20Cabañas%20Chan%20Chan'><img src={fixedButtonMobile} width='55' height='45' alt='consultas' /></a>
        <a className='fixed-call-mobile' href='tel:56954005083'><img src={fixedCallMobile} width='34' height='34' alt='llamadas' /></a>
        <Navbar className='navbar' expand='lg'>
          <Navbar.Brand onClick={this.handleSectionHome}>
            <img className='brand' alt='logotipo' src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav'/>
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'>
              <Nav.Link onClick={this.handleSectionCabanas}>Cabañas</Nav.Link>
              <Nav.Link onClick={this.handleSectionReservas}>Tarifas - Reservas</Nav.Link>
              <Nav.Link onClick={this.handleSectionAtractivos}>Atractivos Turisticos</Nav.Link>
              <Nav.Link onClick={this.handleSectionPromociones}>Promociones</Nav.Link>
              <Nav.Link onClick={this.handleSectionFaq}>FAQ</Nav.Link>
              <Nav.Link href='http://condominiochanchan.com/reglamento2023.pdf' target='_blank' rel='noopener noreferrer'>Descarga Reglamento</Nav.Link>
            </Nav>
            <Form inline>
              <a onClick={this.handleSectionHome} href='#maps' className='iconos'><img src={ubicacion} width='23'height='23' alt='ubicacion' /></a>
              <a href='https://www.facebook.com/cabanaschanchan' target='_blank' rel='noopener noreferrer' className='iconos'><img
                src={facebook} width='23' height='23' alt='facebook' /></a>
              <a href='https://www.instagram.com/cabanaschanchan/' target='_blank' rel='noopener noreferrer' className='iconos'>
                <img src={instagram} width='23' height='23' alt='instagram' /></a>
              <a href='https://api.whatsapp.com/send?phone=56954005083&text=Hola,%20Me%20Gustaría%20tener%20información%20de%20Cabañas%20Chan%20Chan' className='iconos'><img src={whatsapp} width='23'height='23' alt='whatsapp' /></a>
              <a href='mailto:contacto@cabanaschanchan.cl' className='iconos'><img src={mensaje} width='23'height='23' alt='mensaje' /></a>
            </Form>
          </Navbar.Collapse>
        </Navbar>
        {/* <div className='extra-publicidad' onClick={this.togglePopup.bind(this)}> */}
        <div className='extra-publicidad'>
          <p className='contact-email-publicidad'> Promoción TEMPORADA BAJA </p>
        </div>
        {report}
        <footer>
          <img src={bookingDesktop} className='booking-image-desktop' alt='booking' />
          <img src={bookingMobile} className='booking-image-mobile' alt='booking' />
          <div>
            <a href='https://serviciosturisticos.sernatur.cl/1050-cabanas-chan-chan' target='_blank' rel='noopener noreferrer'><img src={confianza} className='turistica-image' alt='confianza' /></a>
            <a href='https://registro.sernatur.cl/registroempresarios/fichaservicio?sid=1050&sign=5055cbf43fac3f7e2336b27310f0b9ef' target='_blank' rel='noopener noreferrer'><img src={turistico} className='turistica-image' alt='turistico' /></a>
          </div>
          <div>
            <p>Ruta P60r Km 46,5 – Sector Chanchan, Lago Lanalhue – Contulmo, Región del Bío Bío</p>
            <p>+56954005083 </p>
            <p><a href='https://api.whatsapp.com/send?phone=56954005083&text=Hola,%20Me%20Gustaría%20tener%20información%20de%20Cabañas%20Chan%20Chan' className='iconos'><img src={whatsapp} width='23'height='23' alt='whatsapp' /></a></p>
          </div>
        </footer>
      </div>
    )
  }
}
