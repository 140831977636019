import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Modal, Drawer, Button } from 'antd'
import './../App.css'
import 'antd/dist/antd.css'

import link01 from './../assets/lightbox/hottubs/hottubs001.jpg'
import link02 from './../assets/lightbox/hottubs/hottubs002.jpg'
import link03 from './../assets/lightbox/hottubs/hottubs003.jpg'
import link04 from './../assets/lightbox/hottubs/hottubs004.jpg'
import link05 from './../assets/lightbox/hottubs/hottubs005.jpg'
import link06 from './../assets/lightbox/hottubs/hottubs006.jpg'
import link07 from './../assets/lightbox/hottubs/hottubs007.jpg'
import link08 from './../assets/lightbox/hottubs/hottubs008.jpg'
import link09 from './../assets/lightbox/hottubs/hottubs009.jpg'
import link10 from './../assets/lightbox/hottubs/hottubs010.jpg'

const PopUpTinajas = (props) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
    return (
      <>
        <Modal
          centered
          visible={props.show}
          onOk={() => props.closePopup(false)}
          onCancel={() => props.closePopup(false)}
          footer={null}
          width={'100%'}
        >
          <Button type="primary" onClick={showDrawer}>Detalles: Tinajas</Button>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link01}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link02}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link03}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link04}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link05}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link06}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link07}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link08}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link09}
                  alt='hot tubs'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link10}
                  alt='hot tubs'
                />
              </Carousel.Item>
            </Carousel>
            <Drawer
                title="Tinajas"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
              >
              <h3>Tinajas</h3>
              <p>Contamos con dos tinajas de agua caliente (hot tubs) colocadas sobre terrazas rústicas, techadas e iluminadas para la noche. Poseen una vista privilegiada del Lago Lanalhue, rodeadas de vegetación. La capacidad de las tinajas son para 2 a 3 personas y para 5 a 6 personas. Además de disfrutar del paisaje, las tinajas proveen de numerosos beneficios para la salud.</p>
              <p><b>Se arriendan con reserva de al menos 24 hrs y su uso es exclusivo para la cabaña que la reserve.</b></p>
            </Drawer>
        </Modal>
      </>
    )
}

export default PopUpTinajas
