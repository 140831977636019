import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { Modal, Drawer, Button } from 'antd'
import './../App.css'
import 'antd/dist/antd.css'

import link01 from './../assets/lightbox/tuneles/tuneles01.jpg'
import link02 from './../assets/lightbox/tuneles/tuneles02.jpg'
import link03 from './../assets/lightbox/tuneles/tuneles03.jpg'
import link04 from './../assets/lightbox/tuneles/tuneles04.jpg'
import link05 from './../assets/lightbox/tuneles/tuneles05.jpg'
import link06 from './../assets/lightbox/tuneles/tuneles06.jpg'
import link07 from './../assets/lightbox/tuneles/tuneles07.jpg'
import link08 from './../assets/lightbox/tuneles/tuneles08.jpg'
import link09 from './../assets/lightbox/tuneles/tuneles09.jpg'
import link10 from './../assets/lightbox/tuneles/tuneles10.jpg'
import link11 from './../assets/lightbox/tuneles/tuneles11.jpg'
import link12 from './../assets/lightbox/tuneles/tuneles12.jpg'

const PopUpTuneles = (props) => {
  const [visible, setVisible] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }
    return (
      <>
        <Modal
          centered
          visible={props.show}
          onOk={() => props.closePopup(false)}
          onCancel={() => props.closePopup(false)}
          footer={null}
          width={'100%'}
        >
          <Button type="primary" onClick={showDrawer}>Detalles: Antiguos Tuneles Ferroviarios</Button>
            <Carousel>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link01}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link02}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link03}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link04}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link05}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link06}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link07}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link08}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link09}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link10}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link11}
                  alt='tuneles'
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className='d-block w-100w'
                  src={link12}
                  alt='tuneles'
                />
              </Carousel.Item>
            </Carousel>
            <Drawer
                title="Atractivos Turisticos"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
              >
              <h3>Antiguos Tuneles Ferroviarios</h3>
              <p>Red de antiguos túneles ferroviarios que cruzan la Cordillera de Nahuelbuta, pertenecientes al ramal Lebu – Los Sauces.</p>
              <p>Desde Contulmo se puede acceder a los túneles La Huiña, Contulmo, Nahuelbuta, Sanzana, entre otros, con distancias de hasta casi un kilómetro de longitud.</p>
            </Drawer>
        </Modal>
      </>
    )
  }
  
  export default PopUpTuneles
